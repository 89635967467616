// Notification
import { ref } from '@vue/composition-api'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import logo from '@/assets/images/logo/logo-black.png'
import { boldFont } from '@/assets/fonts/ar/SSTArabic-Bold'
import { regularFont } from '@/assets/fonts/ar/SSTArabic-Light'

export default function useB2BInvoice(invoiceDetails) {
  const iFrameSrc = ref(null)

  // const subscriptionsInvoice = ref(null)

  console.log('test b2b invoice')

  const exportSubscriptionsInvoiceTotals = ref(null)

  const formatData = () => {
    exportSubscriptionsInvoiceTotals.value = [
      {
        key: 'Total Taxable Amount (Excluding VAT)\n(الإجمالي الخاضع للضریبة)غیر شامل ضریبة القیمة المضافة',
        amount: `${parseFloat(invoiceDetails.value.total_without_vat.toFixed(2)).toLocaleString()} SAR`,
      },
      {
        key: 'Total Vat\nمجموع ضریبة القیمة المضافة',
        amount: `${parseFloat(invoiceDetails.value.total_vat.toFixed(2)).toLocaleString()} SAR`,
      },
      {
        key: 'Total Taxable Amount\n(الإجمالي الخاضع للضریبة)غیر شامل ضریبة القیمة المضافة',
        amount: `${parseFloat(invoiceDetails.value.total_taxable_amount.toFixed(2)).toLocaleString()} SAR`,
      },
      {
        key: 'Discount',
        amount: `${parseFloat(invoiceDetails.value.discount.toFixed(2)).toLocaleString()} SAR`,
      },
      {
        key: 'Total\nإجمالي',
        amount: `${parseFloat(invoiceDetails.value.total.toFixed(2)).toLocaleString()} SAR`,
      },
    ]
  }

  const exportPDF = () => {
    formatData()

    const doc = new jsPDF('p', 'px', 'a4')

    // Add fonts
    doc.addFileToVFS('SSTArabic-Light.ttf', regularFont)
    doc.addFont('SSTArabic-Light.ttf', 'SSTArabic', 'regular')

    doc.addFileToVFS('SSTArabic-Bold.ttf', boldFont)
    doc.addFont('SSTArabic-Bold.ttf', 'SSTArabic', 'bold')

    doc.setFontSize(10)
    doc.setFont('helvetica', 'bold')

    // Center
    // doc.addImage(logo, 'png', 185, 30, 70, 50)
    // doc.addImage(logo, 'png', 185, 40)
    doc.setFont('SSTArabic', 'bold') // set font
    doc.setFontSize(18)
    doc.text(invoiceDetails.value.seller.name, 220, 40, null, null, 'center')
    doc.setFontSize(12)
    doc.text(invoiceDetails.value.seller.address, 220, 60, null, null, 'center')
    doc.setFontSize(15)
    doc.text('فاتورة ضريبية', 220, 100, null, null, 'center')
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('Tax Invoice', 220, 115, null, null, 'center')

    doc.setFontSize(10)

    // Invoice Number
    doc.autoTable({
      body: [{ en: 'Invoice#', value: invoiceDetails.value.invoice_id, ar: 'رقم الفاتورة' }],
      theme: 'striped',
      margin: { top: 135, bottom: 50 },
      tableWidth: doc.internal.pageSize.getWidth() / 1.8,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { ar: { halign: 'right' }, value: { halign: 'center' } },
    })

    doc.addImage(invoiceDetails.value.qr_image, doc.internal.pageSize.getWidth() - 120, doc.lastAutoTable.finalY / 1.5, 100, 100)

    // Invoice Issue Date
    doc.autoTable({
      body: [
        { en: 'Invoice Issue Date', value: invoiceDetails.value.issue_date, ar: 'تاریخ إصدار الفاتورة' },
        { en: 'P.O.#', value: `SO-${invoiceDetails.value.invoice_id}`, ar: 'رقم طلب الشراء' },
      ],
      theme: 'striped',
      margin: { top: 100, bottom: 50 },
      tableWidth: doc.internal.pageSize.getWidth() / 1.8,
      startY: doc.lastAutoTable.finalY + 20,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { ar: { halign: 'right' }, value: { halign: 'center' } },
    })

    const lastTable = doc.lastAutoTable.finalY + 20

    // Seller
    doc.autoTable({
      body: [
        { en: 'Seller', value: '', ar: 'تاجر' },
        { en: 'Name', value: invoiceDetails.value.seller.name, ar: 'اسم' },
        { en: 'Address Line 1', value: invoiceDetails.value.seller.address, ar: '1 عنوان' },
        { en: 'Address Line 2', value: invoiceDetails.value.seller.address_2, ar: '2 عنوان' },
        { en: 'City', value: invoiceDetails.value.seller.city, ar: 'مدينة' },
        { en: 'Country', value: invoiceDetails.value.seller.country, ar: 'دولة' },
        { en: 'ZIP/Postal Code', value: invoiceDetails.value.seller.postal_code, ar: 'الرمز/ الكود البريدي' },
        { en: 'Phone', value: invoiceDetails.value.seller.phone, ar: 'هاتف' },
        { en: 'TRN', value: invoiceDetails.value.seller.TRN, ar: 'TRN' },
        { en: 'Other Seller ID', value: `Company ID: ${invoiceDetails.value.seller.cr_number}`, ar: 'معرف الآخر' },
      ],
      theme: 'striped',
      margin: { top: 100, bottom: 50, right: 230 },
      tableWidth: doc.internal.pageSize.getWidth() / 2.32,
      startY: lastTable,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { ar: { halign: 'right' }, value: { halign: 'center' } },
    })

    // Buyer
    doc.autoTable({
      body: [
        { en: 'Buyer', value: '', ar: 'مشتر' },
        { en: 'Name', value: invoiceDetails.value.entity.business_name, ar: 'اسم' },
        { en: 'Address Line 1', value: invoiceDetails.value.entity.address, ar: '1 عنوان' },
        { en: 'Address Line 2', value: invoiceDetails.value.entity.address_2, ar: '2 عنوان' },
        { en: 'City', value: invoiceDetails.value.entity.city, ar: 'مدينة' },
        { en: 'Country', value: invoiceDetails.value.entity.country, ar: 'دولة' },
        { en: 'ZIP/Postal Code', value: invoiceDetails.value.entity.postal_code, ar: 'الرمز/الكود البريدي' },
        { en: 'Phone', value: invoiceDetails.value.entity.owner_contact, ar: 'هاتف' },
        { en: 'TRN', value: invoiceDetails.value.entity.tax_id, ar: 'TRN' },
        { en: 'Other Buyer ID', value: `Company ID: ${invoiceDetails.value.entity.cr_number}`, ar: 'معرف الآخر' },
      ],
      theme: 'striped',
      margin: { top: 100, bottom: 50, left: 225 },
      tableWidth: doc.internal.pageSize.getWidth() / 2.32,
      startY: lastTable,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { ar: { halign: 'right' }, value: { halign: 'center' } },
    })

    doc.setFont('SSTArabic', 'bold')
    doc.text('Line Items', 30, doc.lastAutoTable.finalY + 50)
    doc.autoTable({
      body: invoiceDetails.value.items,
      columns: [
        { header: 'Nature Of Service\nتفاصیل الخدمة', dataKey: 'name' },
        { header: 'Unit Price\nسعر الوحدة', dataKey: 'price' },
        { header: 'Quantity\nالكمیة', dataKey: 'quantity' },
        { header: 'Taxable Amount', dataKey: 'taxable_amount' },
        { header: 'Discount', dataKey: 'discount' },
        { header: 'Tax Rate', dataKey: 'tax_rate' },
        { header: 'Tax Amount', dataKey: 'tax_amount' },
        { header: 'Item Subtotal (Including VAT)\nالمجموع\n(شامل ضریبة القیمة المضافة)', dataKey: 'subtotal' },
      ],
      theme: 'striped',
      startY: doc.lastAutoTable.finalY + 55,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { price: { halign: 'right' }, quantity: { halign: 'center' }, subtotal: { halign: 'right' } },
    })

    // doc.text('Totals', 30, doc.lastAutoTable.finalY + 25)
    doc.autoTable({
      head: [[{
        content: 'Totals',
        colSpan: 2,
        styles: { fillColor: [204, 204, 204] },
      }]],
      body: exportSubscriptionsInvoiceTotals.value,
      columns: [
        { header: 'key', dataKey: 'key' },
        { header: 'amount', dataKey: 'amount' },
      ],
      theme: 'striped',
      startY: doc.lastAutoTable.finalY + 20,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { key: { fontStyle: 'bold' }, amount: { halign: 'right' } },
    })

    doc.setFont('SSTArabic', 'bold')
    doc.text('Tax Summary', 30, doc.lastAutoTable.finalY + 20)
    // Tax Summary
    doc.autoTable({
      body: [
        { en: 'Tax Details\nالمعلومات الضريبية', value: 'Taxable Amount (SAR)\n', ar: 'Tax Amount\nمبلغ الضريبة' },
        { en: 'Standard Rate (15%)\nالنسبة الاساسية (15%)', value: `${parseFloat(invoiceDetails.value.total_without_vat.toFixed(2)).toLocaleString()} SAR`, ar: `${parseFloat(invoiceDetails.value.total_vat.toFixed(2)).toLocaleString()} SAR` },
        { en: 'Total\nالاجمالي', value: `${parseFloat(invoiceDetails.value.total_without_vat.toFixed(2)).toLocaleString()} SAR`, ar: `${parseFloat(invoiceDetails.value.total_vat.toFixed(2)).toLocaleString()} SAR` },
      ],
      theme: 'striped',
      startY: doc.lastAutoTable.finalY + 25,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fontStyle: 'Bold',
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { value: { halign: 'right' }, ar: { halign: 'right' } },
    })

    // doc.addPage()

    // // Footer Text Arabic
    // doc.setFont('SSTArabic', 'bold')
    // doc.text(footerHeaderAR, doc.internal.pageSize.getWidth() - 30, 30, null, null, 'right')

    // const splitfooterText1AR = doc
    //   .setFontSize(8)
    //   .splitTextToSize(footerText1AR, doc.internal.pageSize.getWidth())

    // doc
    //   .setFont('SSTArabic', 'regular')
    //   .text(splitfooterText1AR, doc.internal.pageSize.getWidth() - 30, 40, null, null, 'right')

    // Footer ON each Page
    const pageCount = doc.internal.getNumberOfPages()

    // For each page, print the page number and the total pages
    for (let i = 1; i <= pageCount; i++) {
      doc.setDrawColor(0, 0, 0)
      doc.line(30, doc.internal.pageSize.getHeight() - 20, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 20)
      // Go to page i
      doc.setPage(i)
      doc.text(`Page ${String(i)} of ${String(pageCount)}`, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 10, null, null, 'right')
    }
    // doc.output('dataurlnewwindow', { filename: 'Subscriptions Invoice.pdf' })

    iFrameSrc.value = doc.output('datauristring')
  }

  return {
    iFrameSrc,

    exportPDF,
  }
}
