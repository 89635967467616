import { posInvoiceAPI } from '@/services/apis'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchPosInvoice(ctx, params) {
      try {
        return await this.$axios.get(posInvoiceAPI, { params })
      } catch (error) {
        return error
      }
    },
  },
}
