<template>
  <iframe
    :src="iFrameSrc"
    :width="windowWidth"
    :height="windowHeight"
    frameborder="0"
  />
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import router from '@/router'
import posStoreModule from './posStoreModule'
import useB2BInvoice from '../../invoice/useB2BInvoice'

export default {
  setup() {
    const POS_STORE_MODULE_NAME = 'app-pos'

    // Register module
    if (!store.hasModule(POS_STORE_MODULE_NAME)) store.registerModule(POS_STORE_MODULE_NAME, posStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(POS_STORE_MODULE_NAME)) store.unregisterModule(POS_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const invoiceDetails = ref(null)

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    const { iFrameSrc, exportPDF } = useB2BInvoice(invoiceDetails)

    const fetchPosInvoice = async id => {
      try {
        const response = await store.dispatch('app-pos/fetchPosInvoice', id)
        // eslint-disable-next-line prefer-destructuring
        invoiceDetails.value = response?.data?.data[0]
        exportPDF()
      } catch (error) {
        console.error(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching POS Invoice',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    fetchPosInvoice(router.currentRoute.query)

    return {
      iFrameSrc,

      windowWidth,
      windowHeight,
    }
  },
}
</script>
